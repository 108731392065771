import editionetude from "@content/advanced/images/acces-edition-etude.png";
import modemodele from "@content/advanced/images/modeeditionmodele.png";
import editionmodele from "@content/advanced/images/editionmodele.png";
import creersection from "@content/advanced/images/creationsection.png";
import files from "@content/advanced/images/upload-files.png";

import { PageLayout, Section, Note } from "@components";

import "@styles/Page.scss";

export default function FeatModels() {
  return (
    <PageLayout title="Form Templates" roles={["admin"]}>
      <p>
        Templates allow you to add variables to your steps. It is possible to
        use the same template for multiple steps.
      </p>
      <Section
        id="accessing-template-editing"
        title="Accessing Template Editing"
      >
        <p>
          Go to the <b>Design</b> section and then <b>Study Editing</b>.
        </p>
        <img src={editionetude} alt="Study Editing Tab" className="centered" />
        <p>
          Switch the interface to <b>Template Editing</b> mode by clicking the
          toggle at the top right of your screen.
        </p>
        <img
          src={modemodele}
          alt="Switch to Template Editing Mode"
          className="centered"
        />
      </Section>
      <Section id="creating-a-template" title="Creating a Template">
        <p>
          Click on <b>Add a Template</b>. You can give your template a name and
          define the type of event that will be associated with the form.
        </p>
        <img src={editionmodele} alt="New Template" className="centered" />
      </Section>
      <Section id="creating-a-section" title="Creating a Section">
        <p>
          Click on the <b>Add a Section</b> button. You can then name your
          section (optional).
        </p>
        <img src={creersection} alt="New Section" className="centered" />
        <p>
          In each section, you can add new{" "}
          <a href="/en/advanced/variables">
            <b>variables</b>
          </a>{" "}
          or pre-registered variables by clicking the "+" button or the
          magnifying glass.
        </p>
        <Note type="note">
          A variable is unique but can be used multiple times in the same form.
          Data entered will be synchronized between the two displayed fields. If
          a variable is reused by mistake in the same form, it will be
          highlighted with a yellow frame.
        </Note>
        <p>
          You can also associate files with the section you just created by
          clicking the <b>Add Files</b> icon at the top right of the section.
        </p>
        <img src={files} alt="Add Files" className="centered" />
        <p>
          A window will open, allowing you to name the file and provide the link
          to your file. When you are finished, press <b>Validate</b>.
        </p>
        <Note type="caution">
          Don’t forget to click the <b>SAVE</b> button when you have finished
          modifying your template.
        </Note>
      </Section>
    </PageLayout>
  );
}
